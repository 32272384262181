import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import CalendarEmptyStateIcon from "@/core/ui/images/empty-state-illustrations/calendar.svg"
import OrganizationOccurrenceListItem, {
  OrganizationOccurrenceListItemProps,
} from "@/organization/occurrence/OrganizationOccurrenceListItem"
import {
  OrganizationOccurrenceListNextPaginationQuery,
  OrganizationOccurrencesDatetimeFilter,
} from "@/organization/occurrence/__generated__/OrganizationOccurrenceListNextPaginationQuery.graphql"
import {
  OrganizationOccurrenceListNext_PaginationFragment$key,
  ProductStatus,
} from "@/organization/occurrence/__generated__/OrganizationOccurrenceListNext_PaginationFragment.graphql"
import { OrganizationOccurrenceListPreviousPaginationQuery } from "@/organization/occurrence/__generated__/OrganizationOccurrenceListPreviousPaginationQuery.graphql"
import { OrganizationOccurrenceListPrevious_PaginationFragment$key } from "@/organization/occurrence/__generated__/OrganizationOccurrenceListPrevious_PaginationFragment.graphql"
import { OrganizationOccurrenceList_appQuery } from "@/organization/occurrence/__generated__/OrganizationOccurrenceList_appQuery.graphql"
import { OrganizationOccurrenceList_organizationQuery } from "@/organization/occurrence/__generated__/OrganizationOccurrenceList_organizationQuery.graphql"
import useGetGroupName from "@/product/common/member-group/common/utils/useGetGroupName"
import { OccurrenceListItemSkeleton } from "@/product/course/event/list/item/OccurrenceListItem"
import useIsAdminViewingAsMember from "@/product/util/hook/useIsAdminViewingAsMember"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoEmptyState } from "@disco-ui"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { isSameDayOrAfter, isSameDayOrBefore } from "@utils/time/timeUtils"
import { utcToZonedTime } from "date-fns-tz"
import formatInTimeZone from "date-fns-tz/formatInTimeZone/index.js"
import { useEffect, useRef, useState } from "react"
import {
  graphql,
  useLazyLoadQuery,
  usePaginationFragment,
  useSubscribeToInvalidationState,
} from "react-relay"
import { ListRange, Virtuoso, VirtuosoHandle } from "react-virtuoso"

export type EventsCalendarQueryParams = {
  calendarTab?: EventsFilterOption
  date?: string
  jumpDate?: string
  dateFilter?: EventsDateFilterOption
  productIds?: string
}

const EVENTS_PER_PAGE = 12
const TEST_ID = "OrganizationOccurrenceList"

// Start with a high initial index as we prepend items to the list which will
// take up indices 9999, 9998 etc and we want to avoid negative indices
const FIRST_ITEM_INDEX_OFFSET = 10000

export type EventsDateFilterOption = Exclude<
  OrganizationOccurrencesDatetimeFilter,
  "%future added value"
>

export const EVENTS_FILTER_OPTIONS: {
  title: string
  value: EventsFilterOption
}[] = [
  {
    title: "Upcoming",
    value: "upcoming",
  },
  {
    title: "Past",
    value: "past",
  },
  {
    title: "Recordings",
    value: "recordings",
  },
]

export function getEventFilters(opts: {
  filter?: EventsFilterOption
  dateFilter?: EventsDateFilterOption
  productIds?: GlobalID[]
}) {
  const { filter } = opts

  const isDateFilter = filter && ["upcoming", "past"].includes(filter)

  return {
    hasRecording: filter === "recordings" ? true : undefined,
    datetimeFilter:
      opts.dateFilter ||
      (isDateFilter ? (filter as OrganizationOccurrencesDatetimeFilter) : undefined),
    includedProductIds: opts.productIds,
    excludeCommunityEvents: Boolean(opts.productIds?.length),
  }
}

export type EventsFilterOption = EventsDateFilterOption | "recordings" | "all"

interface Props {
  filter: EventsFilterOption
  excludeProductEvents?: boolean
  anchorDate: Date
  appId?: GlobalID
}

function OrganizationOccurrenceList({
  appId = "",
  filter,
  anchorDate,
  excludeProductEvents,
}: Props) {
  const timezone = useUserTimezone()
  const activeOrganization = useActiveOrganization()!
  const isViewingAsMember = useIsAdminViewingAsMember()
  const getName = useGetGroupName()

  const classes = useStyles()

  const [params, setParams] = useQueryParamState<EventsCalendarQueryParams>()

  const virtuoso = useRef<VirtuosoHandle>(null)

  const productIds = params.productIds?.split(",") || []

  const { hasRecording, datetimeFilter, includedProductIds, excludeCommunityEvents } =
    getEventFilters({ filter, dateFilter: params.dateFilter, productIds })

  const dateOrdering = datetimeFilter === "past" || hasRecording ? "DESC" : "ASC"

  const anchor = anchorDate

  const jumpDateRef = useRef(
    params.jumpDate ?? (filter === "all" ? anchor.getTime().toString() : undefined)
  )
  const jumpDateSetRangeRef = useRef<ListRange | null>(null)

  const onJumpDate = jumpDateRef.current === params.date

  const jumpDate = jumpDateRef.current
    ? utcToZonedTime(new Date(parseInt(jumpDateRef.current)), timezone)
    : null

  if (dateOrdering === "DESC") {
    anchor.setHours(23, 59, 59)
    if (jumpDate) jumpDate.setHours(23, 59, 59)
  } else {
    anchor.setHours(0, 0, 0)
    if (jumpDate) jumpDate.setHours(0, 0, 0)
  }

  const { organization } = useLazyLoadQuery<OrganizationOccurrenceList_organizationQuery>(
    graphql`
      query OrganizationOccurrenceList_organizationQuery(
        $id: ID!
        $first: Int!
        $after: String
        $last: Int!
        $before: String
        $anchorDate: DateTime!
        $datetimeFilter: OrganizationOccurrencesDatetimeFilter
        $excludeProductEvents: Boolean
        $excludeCommunityEvents: Boolean
        $includedProductIds: [ID!]
        $hasRecording: Boolean
        $appId: ID!
      ) {
        organization: node(id: $id) {
          ... on Organization {
            ...OrganizationOccurrenceListNext_PaginationFragment
              @arguments(
                first: $first
                after: $after
                startsAfter: $anchorDate
                datetimeFilter: $datetimeFilter
                excludeProductEvents: $excludeProductEvents
                excludeCommunityEvents: $excludeCommunityEvents
                hasRecording: $hasRecording
                includedProductIds: $includedProductIds
                appId: $appId
              )
            ...OrganizationOccurrenceListPrevious_PaginationFragment
              @arguments(
                first: $last
                after: $before
                startsBefore: $anchorDate
                datetimeFilter: $datetimeFilter
                excludeProductEvents: $excludeProductEvents
                excludeCommunityEvents: $excludeCommunityEvents
                hasRecording: $hasRecording
                includedProductIds: $includedProductIds
                appId: $appId
              )
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
      first: EVENTS_PER_PAGE,
      last: EVENTS_PER_PAGE,
      hasRecording,
      datetimeFilter,
      excludeProductEvents,
      excludeCommunityEvents,
      includedProductIds,
      // The anchor date is used to determine where to start from when paginating in either direction
      // This is used to jump to a specific date when the user clicks on a date in the calendar
      // nextData will include occurrences after the anchor date, and previousData will before the anchor date
      anchorDate: anchor.toISOString(),
      appId,
    },
    { fetchPolicy: "network-only" }
  )

  const { app } = useLazyLoadQuery<OrganizationOccurrenceList_appQuery>(
    graphql`
      query OrganizationOccurrenceList_appQuery($id: ID!) {
        app: node(id: $id) {
          ... on ProductApp {
            visibilityGroups {
              edges {
                node {
                  id
                  name
                  kind
                  role
                  product {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      id: appId,
    }
  )

  const {
    data: nextData,
    refetch: refetchNext,
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment<
    OrganizationOccurrenceListNextPaginationQuery,
    OrganizationOccurrenceListNext_PaginationFragment$key
  >(
    graphql`
      fragment OrganizationOccurrenceListNext_PaginationFragment on Organization
      @refetchable(queryName: "OrganizationOccurrenceListNextPaginationQuery")
      @argumentDefinitions(
        first: { type: "Int!" }
        after: { type: "String" }
        startsAfter: { type: "DateTime!" }
        datetimeFilter: { type: "OrganizationOccurrencesDatetimeFilter" }
        excludeProductEvents: { type: "Boolean" }
        excludeCommunityEvents: { type: "Boolean" }
        hasRecording: { type: "Boolean" }
        includedProductIds: { type: "[ID!]" }
        appId: { type: "ID!" }
      ) {
        nextOccurrences: occurrences(
          first: $first
          after: $after
          startsAfter: $startsAfter
          datetimeFilter: $datetimeFilter
          excludeProductEvents: $excludeProductEvents
          excludeCommunityEvents: $excludeCommunityEvents
          hasRecording: $hasRecording
          includedProductIds: $includedProductIds
          appId: $appId
        ) @connection(key: "OrganizationOccurrenceList__nextOccurrences") {
          __id
          totalCount
          edges {
            node {
              id
              datetimeRange
              status
              product {
                slug
                status
              }
              name
              ...OrganizationOccurrenceListItem_OccurrenceFragment
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    organization
  )

  const {
    data: previousData,
    refetch: refetchPrevious,
    loadNext: loadPrevious,
    hasNext: hasPrevious,
    isLoadingNext: isLoadingPrevious,
  } = usePaginationFragment<
    OrganizationOccurrenceListPreviousPaginationQuery,
    OrganizationOccurrenceListPrevious_PaginationFragment$key
  >(
    graphql`
      fragment OrganizationOccurrenceListPrevious_PaginationFragment on Organization
      @refetchable(queryName: "OrganizationOccurrenceListPreviousPaginationQuery")
      @argumentDefinitions(
        first: { type: "Int!" }
        after: { type: "String" }
        startsBefore: { type: "DateTime!" }
        datetimeFilter: { type: "OrganizationOccurrencesDatetimeFilter" }
        excludeProductEvents: { type: "Boolean" }
        excludeCommunityEvents: { type: "Boolean" }
        hasRecording: { type: "Boolean" }
        includedProductIds: { type: "[ID!]" }
        appId: { type: "ID!" }
      ) {
        previousOccurrences: occurrences(
          first: $first
          after: $after
          startsBefore: $startsBefore
          datetimeFilter: $datetimeFilter
          excludeProductEvents: $excludeProductEvents
          excludeCommunityEvents: $excludeCommunityEvents
          hasRecording: $hasRecording
          includedProductIds: $includedProductIds
          appId: $appId
        ) @connection(key: "OrganizationOccurrenceList__previousOccurrences") {
          __id
          totalCount
          edges {
            node {
              id
              datetimeRange
              status
              product {
                slug
                status
              }
              name
              ...OrganizationOccurrenceListItem_OccurrenceFragment
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    organization
  )

  const [el, setEl] = useState<HTMLElement | null>(null)

  // Listen for invalidation in CreateEventForm
  useSubscribeToInvalidationState(
    [
      nextData?.nextOccurrences?.__id ?? "",
      previousData?.previousOccurrences?.__id ?? "",
    ],
    () => {
      if (nextData?.nextOccurrences) {
        refetchNext({}, { fetchPolicy: "network-only" })
      }
      if (previousData?.previousOccurrences) {
        refetchPrevious({}, { fetchPolicy: "network-only" })
      }
    }
  )

  useEffect(() => {
    setEl(document.querySelector<HTMLElement>("#app-page-layout-scroll-container"))
  }, [])

  const visibilityGroups = Relay.connectionToArray(app?.visibilityGroups)
  const nextOccurrences = Relay.connectionToArray(nextData?.nextOccurrences)
  const previousOccurrences = Relay.connectionToArray(previousData?.previousOccurrences)

  const topOccurrences = (dateOrdering === "DESC" ? nextOccurrences : previousOccurrences)
    .filter(filterDraftEventsIfViewingAsMember)
    .reverse()

  const bottomOccurrences = (
    dateOrdering === "DESC" ? previousOccurrences : nextOccurrences
  ).filter(filterDraftEventsIfViewingAsMember)

  const allOccurrences = [...topOccurrences, ...bottomOccurrences]

  const range = useRef<ListRange>({
    startIndex: FIRST_ITEM_INDEX_OFFSET,
    endIndex: FIRST_ITEM_INDEX_OFFSET,
  })

  const latestMonthYear = useRef<string | null>(null)

  if (!allOccurrences.length) {
    return (
      <DiscoEmptyState
        testid={TEST_ID}
        icon={<CalendarEmptyStateIcon width={139} height={152} />}
        {...getEmptyStateTitleAndDescription()}
      />
    )
  }

  if (!el) return null

  const isOnAnchorDate = range.current.startIndex === FIRST_ITEM_INDEX_OFFSET

  const showTopSkeleton =
    dateOrdering === "ASC" && !isOnAnchorDate ? isLoadingPrevious : isLoadingNext
  const showBottomSkeleton = dateOrdering === "ASC" ? isLoadingNext : isLoadingPrevious

  const data = processOccurrences()

  return (
    <Virtuoso
      ref={virtuoso}
      data={data}
      totalCount={data.length}
      initialTopMostItemIndex={
        // Sets which item to initally align with the top of the visible list
        // Required to show a specific date instead of showing the top-most item
        topOccurrences.length + (showTopSkeleton ? 1 : 0)
      }
      firstItemIndex={FIRST_ITEM_INDEX_OFFSET - topOccurrences.length}
      rangeChanged={({ startIndex, endIndex }) => {
        range.current = { startIndex, endIndex }

        if (params.jumpDate) setParams({ jumpDate: undefined })

        // When the list loads it jumps around a bit as items load so we need to first identify
        // that the list has loaded, the jumped to date is visible then identify a stable range before changing the date

        // If indices are the same then list is not loaded
        if (
          startIndex === FIRST_ITEM_INDEX_OFFSET &&
          endIndex === FIRST_ITEM_INDEX_OFFSET
        ) {
          return
        }

        const itemsShown = endIndex - startIndex + 1

        // 1. If we are still currently focused on the jump date check if it came into view
        if (onJumpDate) {
          // We check if the jump date is in view until jumpDateSetRangeRef is set
          if (!jumpDateSetRangeRef.current) {
            if (
              // When at top or mid of list the jump date is considered in view when the first item is in view
              (startIndex <= FIRST_ITEM_INDEX_OFFSET &&
                endIndex >= FIRST_ITEM_INDEX_OFFSET) ||
              // When at bottom the jump date is considered in view when the last item is in view
              (endIndex === FIRST_ITEM_INDEX_OFFSET - 1 && !bottomOccurrences.length)
            ) {
              jumpDateSetRangeRef.current = { startIndex, endIndex }
            }

            return
          }

          // 2. Check if the jump date should still be in view to avoid changing the date

          // If the startIndex is on the initial index consider it not moved
          if (startIndex === FIRST_ITEM_INDEX_OFFSET) return

          // If the list is mostly visible don't change the date
          if (
            allOccurrences.length < EVENTS_PER_PAGE &&
            allOccurrences.length / 2 < itemsShown &&
            topOccurrences.length &&
            bottomOccurrences.length
          ) {
            return
          }

          if (
            // Near or at the bottom of a list
            bottomOccurrences.length < itemsShown
          ) {
            // If endIndex has not changed and startIndex has not changed by more than 1
            if (
              endIndex === jumpDateSetRangeRef.current.endIndex &&
              Math.abs(startIndex - jumpDateSetRangeRef.current.startIndex) < 2
            ) {
              return
            }
          } else if (
            // If startIndex has not changed and endIndex has not changed by more than 1
            startIndex === jumpDateSetRangeRef.current.startIndex &&
            Math.abs(endIndex - jumpDateSetRangeRef.current.endIndex) < 2
          ) {
            return
          }
        }

        // 3. If we have moved from the jump date then set the calendar to the new top item's date
        const item = getTopVisibleItem(startIndex)
        if (!item) return
        setParams({ date: `${new Date(item.datetimeRange[0]).getTime()}` })
      }}
      itemContent={(_, item) => {
        if (item.isSkeleton) {
          return <OccurrenceListItemSkeleton variant={"list-item"} />
        }

        return (
          <OrganizationOccurrenceListItem
            key={item.occurrence.id}
            occurrenceKey={item.occurrence}
            testid={item.testid}
            shouldShowMonthYear={item.shouldShowMonthYear}
            dateSeparator={item.dateSeparator}
            dateOrdering={dateOrdering}
          />
        )
      }}
      customScrollParent={el}
      components={{
        Header: () => {
          if (dateOrdering === "DESC") return renderNextScrolledIntoView()
          return renderPreviousScrolledIntoView()
        },
        Footer: () => {
          return (
            <>
              {dateOrdering === "DESC"
                ? renderPreviousScrolledIntoView()
                : renderNextScrolledIntoView()}
              <div className={classes.footer} />
            </>
          )
        },
      }}
    />
  )

  function getTopVisibleItem(startIndex: number) {
    const isItemInBottom = startIndex >= FIRST_ITEM_INDEX_OFFSET

    if (isItemInBottom) {
      const index = startIndex - FIRST_ITEM_INDEX_OFFSET
      return bottomOccurrences[index]
    }

    const index = topOccurrences.length - (FIRST_ITEM_INDEX_OFFSET - startIndex)
    return topOccurrences[index]
  }

  function renderNextScrolledIntoView() {
    if (!hasNext || onJumpDate) return null
    return (
      <DiscoScrolledIntoView
        isLoading={isLoadingNext}
        onScrolledIntoView={() => {
          loadNext(EVENTS_PER_PAGE)
        }}
        skeleton={<></>}
      />
    )
  }

  function renderPreviousScrolledIntoView() {
    if (!hasPrevious || onJumpDate) return null
    return (
      <DiscoScrolledIntoView
        isLoading={isLoadingPrevious}
        onScrolledIntoView={() => {
          loadPrevious(EVENTS_PER_PAGE)
        }}
        skeleton={<></>}
      />
    )
  }

  type OccurrenceData = (typeof nextOccurrences)[number]
  type ProcessedData = Omit<
    OrganizationOccurrenceListItemProps,
    "occurrenceKey" | "dateOrdering"
  > & {
    occurrence: OccurrenceData
  }
  type DataItem = (ProcessedData & { isSkeleton?: never }) | { isSkeleton: true }

  function processOccurrences(): DataItem[] {
    let previousOccurrence: {
      isJumpDateOrAfter: boolean
      isJumpDateOrBefore: boolean
    } | null = null

    const processedData: ProcessedData[] = allOccurrences.map((occurrence, i) => {
      let shouldShowMonthYear = false

      const currentMonthYear = formatInTimeZone(
        occurrence.datetimeRange[0],
        timezone,
        DATE_FORMAT.FULL_MONTH_AND_YEAR
      )

      // i === 0 is required because if the component rerenders but there is only one monthYear in the list
      // the ref will retain the value and shouldShowMonthYear will be false
      if (currentMonthYear !== latestMonthYear.current || i === 0) {
        shouldShowMonthYear = true
        latestMonthYear.current = currentMonthYear
      }

      let isClosestToJumpDate = false

      const start = new Date(occurrence.datetimeRange[0])

      const isJumpDateOrAfter = jumpDate
        ? isSameDayOrAfter(timezone, start, jumpDate)
        : false
      const isJumpDateOrBefore = jumpDate
        ? isSameDayOrBefore(timezone, start, jumpDate)
        : false

      const isFirst = i === 0
      const isLast = i === allOccurrences.length - 1

      const isPreviousJumpDateOrAfter = Boolean(previousOccurrence?.isJumpDateOrAfter)
      const isPreviousJumpDateOrBefore = Boolean(previousOccurrence?.isJumpDateOrBefore)

      if (dateOrdering === "ASC") {
        if (previousOccurrence) {
          if (!isPreviousJumpDateOrAfter && isJumpDateOrAfter) {
            isClosestToJumpDate = true
          }
        }

        if (isFirst && !hasPrevious) {
          if (isJumpDateOrAfter) {
            isClosestToJumpDate = true
          }
        }

        if (isLast && !hasNext) {
          if (isJumpDateOrBefore && !isPreviousJumpDateOrAfter) {
            isClosestToJumpDate = true
          }
        }
      } else {
        if (previousOccurrence && isJumpDateOrBefore && !isPreviousJumpDateOrBefore) {
          isClosestToJumpDate = true
        }
        if (isFirst && !hasNext && isJumpDateOrBefore) {
          isClosestToJumpDate = true
        }
        if (isLast && !hasPrevious && isJumpDateOrAfter && !isPreviousJumpDateOrAfter) {
          isClosestToJumpDate = true
        }
      }

      // Store the previous occurrence for the next iteration
      previousOccurrence = {
        isJumpDateOrAfter,
        isJumpDateOrBefore,
      }

      let dateSeparator: Date | undefined

      if (isClosestToJumpDate && jumpDate) {
        dateSeparator = jumpDate
      }

      return {
        testid: `${TEST_ID}.occurrence.${i}`,
        occurrence,
        dateSeparator,
        shouldShowMonthYear,
        isSkeleton: false,
      }
    })

    // Add the skeletons in the data so that the list can take the space into account
    // if put in the Header and Footer, the space taken up will cause issues
    return [
      ...ArrayUtils.spreadIf({ isSkeleton: true }, showTopSkeleton),
      ...processedData,
      ...ArrayUtils.spreadIf({ isSkeleton: true }, showBottomSkeleton),
    ] as DataItem[]
  }

  function filterDraftEventsIfViewingAsMember(occurrence: {
    product: { status: ProductStatus }
  }) {
    if (!isViewingAsMember) return true
    return occurrence.product.status !== "draft"
  }

  function getEmptyStateTitleAndDescription() {
    let groupsString = visibilityGroups
      .slice(0, 3)
      .map((group) => {
        let groupName = group.name
        if (group.product) {
          groupName = getName(group.kind, group.name, false, group.product, group.role)
        }
        return groupName
      })
      .join(", ")

    if (visibilityGroups.length > 3) groupsString += "..."

    const subtitleGroupString = `This is a private collection of Events for members of the groups : ${groupsString}`
    switch (datetimeFilter || filter) {
      case "upcoming":
        return {
          title: "There are no upcoming events",
          subtitle: visibilityGroups.length
            ? subtitleGroupString
            : "All upcoming events that are a part of your community are shown here",
        }
      case "past":
        return {
          title: "There are no past events",
          subtitle: visibilityGroups.length
            ? subtitleGroupString
            : "All past events that are a part of your community are shown here",
        }
      case "recordings":
        return {
          title: "There are no events with recordings",
          subtitle: visibilityGroups.length
            ? subtitleGroupString
            : "All event recordings that are a part of your community are shown here",
        }
      default:
        return {
          title: "There are no events",
          subtitle: "All events that are a part of your community are shown here",
        }
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  footer: {
    padding: theme.spacing(2, 0),
  },
}))

function OrganizationOccurrenceListSkeleton() {
  return (
    <>
      <OccurrenceListItemSkeleton variant={"list-item"} />
      <OccurrenceListItemSkeleton variant={"list-item"} />
      <OccurrenceListItemSkeleton variant={"list-item"} />
      <OccurrenceListItemSkeleton variant={"list-item"} />
      <OccurrenceListItemSkeleton variant={"list-item"} />
    </>
  )
}

export default Relay.withSkeleton({
  skeleton: OrganizationOccurrenceListSkeleton,
  component: OrganizationOccurrenceList,
})
